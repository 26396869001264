body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

:root {
  --amplify-primary-color: #589507;
  --amplify-primary-tint: #589507;
  --amplify-primary-shade: #396f04;
}

footer {
  width: 100%;
  height: 20px;
  background-color: #396f04;
  color: white;
  text-align: center;
}

ul.footer-menu li {
  display: inline;
}
